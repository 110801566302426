import firebase from 'firebase/app'

export default class TwitterResister {
  constructor() {
    this.label = ''
  }

  async add(payload) {
    try {
      const { uid, twitterName } = payload
      let hasDocument = false
      await firebase.firestore().collection('user').doc(uid)
        .get()
        .then(doc => {
          const data = doc.data()
          if (data) hasDocument = true
        })

      if (hasDocument) return { status: 'success' }

      const d = {
        docID: uid,
        insertTime: new Date(),
        type: 'consumer',
        mail: '',
        displayStatus: 'active',
        uid,
        twitterName,
        isUserProfile: false,
        provider: 'twitter',
      }

      await firebase.firestore().collection('user').doc(uid).set(d, { merge: true })

      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        status: 'error',
        msg: 'ユーザ登録が正常に登録されませんでした。',
      }
      return response
    }
  }
}
