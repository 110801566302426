import firebase from 'firebase/app'

export default async function singIn(payload) {
  const { mailaddress, password } = payload

  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase.auth().signInWithEmailAndPassword(mailaddress, password)
      })
    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
