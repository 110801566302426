<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1">
            転職コレクション
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          ようこそ、転コレへ！
        </b-card-title>
        <b-card-text class="mb-2">
          <div class="">
            メールアドレスとパスワードを入力して下さい。
          </div>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="mail"
              label="Mail"
            >
              <validation-provider
                name="Mail"
              >
                <b-form-input
                  id="mail"
                  v-model="mailaddress"
                  name="login-email"
                  placeholder="1234"
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge input_"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div
              v-if="showPasswdError"
              class="text-danger text-center"
            >
              パスワードが正しくありません。
            </div>

            <!-- submit button -->
            <b-button
              v-if="status == 1"
              variant="primary"
              block
              :disabled="invalid"
              style="margin: 10% 0;"
              @click="login"
            >
              ログイン
            </b-button>
            <div
              v-if="status == 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
          </b-form>
          <div class="text-center">
            <small>Twitterでログイン</small>
            <a
              @click="twitterLogin"
            >
              <div class="login-img text-center">
                <b-img
                  src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/Twitter_logo.png?alt=media&token=07758c94-caef-4d8a-b219-1e3f0a758c98"
                  rounded
                  fluid
                  alt="Twitterログイン"
                />
              </div>
            </a>
          </div>
          <div class="text-center mt-3">
            <a
              @click="moveToCreateAccount"
            >
              <small>アカウントをお持ちでない方はこちらから</small>
              <feather-icon
                size="14"
                icon="EditIcon"
              />
            </a>
          </div>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BSpinner, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import singIn from '@/firestore/authentication/login'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import firebase from 'firebase/app'
import store from '@/store'
import TwitterResister from '@/firestore/authentication/TwitterResister'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BImg,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errorMSG: '',
      required,
      email,
      status: 1,
      mailaddress: '',
      password: '',
      showPasswdError: false,
      logoPic: '@/assets/images/logo/Twitter social icons - rounded square - blue.png',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.$store.dispatch('userBasic/resetAll')
  },
  methods: {
    async login() {
      this.status = 2
      const isLogined = await firebase.auth().signInWithEmailAndPassword(this.mailaddress, this.password)
        .then(
          res => {
            res.user.getIdToken().then(idToken => {
              localStorage.setItem('jwt', idToken.toString())
              useJwt.setToken(idToken.toString())
              store.dispatch('userBasic/updateUid', res.user.uid)
              store.dispatch('userBasic/updateMail', res.user.email)
            })
            return true
          },
        )
        .catch(error => {
          // window.console.log('memo', error.message)
          if (error.message === 'The password is invalid or the user does not have a password.') this.showPasswdError = true
        })

      if (isLogined) {
        await firebase.firestore().collection('user')
          .where('mail', '==', this.mailaddress)
          .get()
          .then(sn => {
            if (!sn.empty) {
              sn.forEach(doc => {
                const data = doc.data()
                /* eslint-disable */
                const userData = {
                  "id": data.uid,
                  "email": data.mail,
                  "role": "admin",
                  "ability":[{"action":"manage",
                  "subject":"all"}],
                  "extras":{"eCommerceCartItemsCount":5},
                }
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)
                store.dispatch('userBasic/updateUserType', data.type)
                // ユーザタイプによって分ける：①コレクト→管理画面、②一般→ダッシュボード
                this.$router.replace(getHomeRouteForLoggedInUser(data.type))
                this.status = 1
                /* eslint-enable */
              })
            }
          })
        return
      }
      this.status = 1
    },
    async twitterLogin() {
      this.status = 2
      const provider = new firebase.auth.TwitterAuthProvider()
      // const provider = firebase.auth().TwitterAuthProvider()
      await firebase.auth().signInWithPopup(provider)
        .then(
          async result => {
            const { accessToken, secret } = result.credential
            const { user, additionalUserInfo } = result
            window.console.log('memo', secret.length, result)
            store.dispatch('userBasic/updateUid', user.uid)

            const d = {
              uid: user.uid,
              twitterName: additionalUserInfo.profile.screen_name,
            }
            const twitterResister = new TwitterResister()
            await twitterResister.add(d)

            localStorage.setItem('jwt', accessToken.toString())
            /* eslint-disable */
            const userData = {
              "id": user.uid,
              "fullName": "John Doe",
              "username": "johndoe",
              "avatar": "/img/13-small.d796bffd.png",
              "email": 'abc@sample.com',
              "role": "admin",
              "ability":[{"action":"manage",
              "subject":"all"}],
              "extras":{"eCommerceCartItemsCount":5},
            }
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)
            // ユーザタイプによって分ける：①コレクト→管理画面、②一般→ダッシュボード
            this.$router.push({ name: 'dashboad' })
            this.status = 1
            /* eslint-enable */
          },
        )
    },
    async login_backup() {
      await firebase.auth().signInWithEmailAndPassword(this.mailaddress, this.password)
        .then(
          res => {
            res.user.getIdToken().then(idToken => {
              localStorage.setItem('jwt', idToken.toString())
              useJwt.setToken(idToken.toString())
              // const ability = {
              //   action: 'manage',
              //   subject: 'all',
              // }
              // this.$ability.update(ability)
              /* eslint-disable */
              const userData = {
                "id": 1,
                "fullName": "John Doe",
                "username": "johndoe",
                "avatar": "/img/13-small.d796bffd.png",
                "email": "admin@demo.com",
                "role": "admin",
                "ability":[{"action":"manage",
                "subject":"all"}],
                "extras":{"eCommerceCartItemsCount":5},
              }
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
              this.status = 1
              this.$router.push({ name: 'jobs' })
              /* eslint-enable */
            })
          },
        )
    },
    async login2() {
      // window.console.log('🐶')
      const data = {
        mailaddress: this.mailaddress,
        password: this.password,
      }
      const response = await singIn(data)
      if (response.status === 'success') {
        // window.console.log('🏎✨️', response.status)
        await this.singup()
        // this.$router.push({ name: 'student-profile' })
        this.status = 1
      } else {
        window.console.log('error', response.msg)
      }
    },
    singup() {
      useJwt.login({
        email: 'admin@demo.com',
        password: 'admin',
      })
        .then(response => {
          const { userData } = response.data
          useJwt.setToken(response.data.accessToken)
          // window.console.log('🌻', response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          // window.console.log('🌺', response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          // window.console.log('🚀', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          // window.console.log('ability🌸', userData.ability)
          this.$router.push({ name: 'applylist' })
          // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    move(val) {
      // window.console.log('memo', val)
      this.$router.push({ name: val })
    },
    moveToCreateAccount() {
      this.$router.push({ path: '/sendmail', query: { type: 'consumer' } })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner_ {
  margin: 3% auto;
}
.input_ {
  font-size: 1.4rem;
  letter-spacing: 3px;
}
.login-img {
  width: 50px;
  margin: 2% auto;
}
</style>
